body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.g-flex-break {
  flex-basis: 100%;
  height: 0;
}

.MuiSlider-valueLabel {
  color: black;
}